export const iceServers = [
  {
    username: "asend",
    credential: "fc2hZjWMyEO27XB",
    urls: "turn:netly.kz:3478",
  },
  { 
    urls: 'stun:netly.kz:3478',
  },
  { urls: 
    'stun:stun.services.mozilla.com' 
  },
];