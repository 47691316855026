import { WayPointModel } from "../models/WayPointModel";

export const swapWayPoints = (wayPoints: WayPointModel[], paths: { lat: number; lng: number}[]) => {
  return wayPoints.map((wp, idx) => {
    if (wp.type === 'wayPoint') {
      return {
        ...wp,
        latitude: paths[idx]?.lat,
        longitude: paths[idx]?.lng,
      };
    }
    return wp;
  });
    
};