import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Flex, useToast } from '@chakra-ui/react';
import { OrderModel } from '@src/features/orders/models/OrderModel';
import { useAutoAssignDroneMutation, useCancelOrderMutation } from '@src/features/orders/redux/api';
import { useDispatch } from 'react-redux';
import { setSelectedGroupOrder, setSelectedNewOrder } from '@src/features/orders/redux/extra-store/actions';

interface OrderPopupActionsProps {
  order: OrderModel;
}

const OrderPopupActions: React.FC<OrderPopupActionsProps> = (props) => {

  const { order } = props;

  const toast = useToast();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [cancelOrder, { isLoading }] = useCancelOrderMutation();
  const [autoAssign, { isLoading: isAutoAssignLoading }] = useAutoAssignDroneMutation();

  const onAuthAssign = async (): Promise<void> => {
    autoAssign(order.id).unwrap()
      .then((response) => {
        toast({ 
          status: 'success', 
          isClosable: true, 
          title: 'Your request was submitted',  
        });
        dispatch(setSelectedNewOrder(null));
        // @ts-ignore
        // [TO-DO] kazhymukhan
        dispatch(setSelectedGroupOrder({ missionId: response.missionId }));
      });
  };

  const onCancel = async (): Promise<void> => {
    cancelOrder({ id: order.id }).unwrap()
      .then(() => {
        toast({ 
          status: 'success', 
          isClosable: true, 
          title: 'Your request was submitted',  
        });
        dispatch(setSelectedNewOrder(null));
      });
  };

  const redirectToUpdateOrder = (): void => navigate(`/app/orders/update/${order.id}`);

  return (
    <Flex direction='column' gap={3}>
      <Flex gap={3}>
        <Button 
          variant='brand' 
          onClick={onAuthAssign}
          isLoading={isAutoAssignLoading}>  
          Auto Assign 
        </Button>
        <Button 
          variant='brand' 
          fontWeight={600} 
          onClick={redirectToUpdateOrder}> 
          Update 
        </Button>
        
      </Flex>

      <Button 
        color='text.snow'
        variant='outline'
        colorScheme='gray' 
        fontWeight={600} 
        onClick={onCancel}
        isLoading={isLoading}
        sx={{ _hover: { bg: 'transparent' } }}> 
          Cancel 
      </Button>

      

    </Flex>
  );
};

export default OrderPopupActions;