import React from 'react';
import { useDeleteDroneMutation } from '@src/features/drones/redux/api';
import { Button, Flex, Modal, ModalContent, ModalOverlay, Text, useToast } from '@chakra-ui/react';
import { DeleteDroneDialogProps } from './types';


const DeleteDroneDialog: React.FC<DeleteDroneDialogProps> = (props) => {
  const { isOpen, onClose, drone } = props;

  const toast = useToast();
  const [deleteDrone, { isLoading }] = useDeleteDroneMutation();

  const onDeleteDrone = () => {
    if (drone) {
      deleteDrone({ id: drone.id }).unwrap()
        .then(() => {
          toast({ 
            status: 'success', 
            isClosable: true, 
            title: 'Your request was submitted', 
          });
        }).finally(() => onClose());
    }
  };

  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent 
        bg='linear-gradient(0deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05)), #121212'>
          
        <Flex p='24px' gap='12px' direction='column'>

          <Text  color='white'>
            Are you sure you want to delete <b>{drone?.serialNumber}</b>?
          </Text>

          <Flex gap='12px' align='center'>

            <Button 
              colorScheme='red' 
              onClick={onDeleteDrone} 
              isLoading={isLoading}> 
              Delete 
            </Button>

            <Button 
              onClick={onClose} 
              isLoading={isLoading}> 
              Cancel 
            </Button>

          </Flex>
        </Flex>
      </ModalContent>
    </Modal>
  );
};

export default DeleteDroneDialog;