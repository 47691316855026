import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Divider, useToast } from "@chakra-ui/react";
import { PopupOrdersList, PopupWrapper } from '../components';
import GroupOrderPopupActions from './group-order-popup-actions';
import { selectorSelectedGroupOrder } from '@src/features/orders/redux/extra-store/selectors';
import { OrderModel } from '@src/features/orders/models/OrderModel';
import { useRequestAuthMutation } from '@src/features/orders/redux/api';

const GroupOrderAssignedPopup: React.FC = () => {

  const toast = useToast();
  const navigate = useNavigate();
  const selected = useSelector(selectorSelectedGroupOrder);
  const [requestAuth, { isLoading }] = useRequestAuthMutation();

  const onRequestAuth = async (): Promise<void> => {
    if (selected && selected.missionId) {
      requestAuth({ missionId: selected.missionId }).unwrap()
        .then((response) => {
          if (response.statusCode === 200) {
            toast({ 
              status: 'success', 
              isClosable: true, 
              title: 'Your request was submitted',  
            });
            return;
          }
          toast({ 
            position: 'top-right',
            status: 'error', 
            isClosable: true, 
            title: response?.message || 'Something went wrong', 
          });

        });
    } 
  };

  const redirectToUpdate = (order: OrderModel): void => {
    navigate(`/app/orders/update/${order.id}`);
  };

  return (
    <PopupWrapper 
      title={`Drone - (${selected?.drone.serialNumber})`}>  
      
      <PopupOrdersList onClick={redirectToUpdate}/>

      <GroupOrderPopupActions />

      <Divider my='18px' borderColor='gray' />

      <Button 
        variant='brand' 
        onClick={onRequestAuth} 
        isLoading={isLoading}> 
        REQUEST AUTHORIZATION 
      </Button>

    </PopupWrapper>
  );
};

export default GroupOrderAssignedPopup;