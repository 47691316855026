import React from 'react';

import { CloseIcon } from '@chakra-ui/icons';
import { Flex, IconButton, Text } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { setSelectedGroupOrder, setSelectedNewOrder } from '@src/features/orders/redux/extra-store/actions';


interface PopupWrapperProps {
    title: string;
    children: React.ReactNode;
}

const PopupWrapper: React.FC<PopupWrapperProps> = (props) => {
  const { children, title } = props;

  const dispatch = useDispatch();

  const onClose = (): void => {
    dispatch(setSelectedNewOrder(null));
    dispatch(setSelectedGroupOrder(null));
  };

  return (
    <Flex  
      p='12px'
      width='100%' 
      maxWidth='400px'

      direction='column'
      
      top='5px'
      left='405px'
      position='absolute'
      zIndex={999}

      borderRadius='4px'
      bg='block.alabaster'>

      <Flex w='100%' align='center' justify='space-between'>
        <Text fontWeight={600} color='text.snow'> {title} </Text>
        <IconButton 
          w='24px'
          h='24px'
          variant='text'
          aria-label='close-icon'  
          onClick={onClose}
          icon={<CloseIcon color='white' />} />
      </Flex>
      {children}
    </Flex>
  );
};

export default PopupWrapper;