import React from 'react';
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { NotAllowedIcon } from '@chakra-ui/icons';
import { ReactComponent as VideoIcon } from '../../assets/video-camera.svg';
import { Flex, IconButton, Skeleton } from '@chakra-ui/react';
import { DroneCameraProps } from './types';
import { useDroneCamera } from '../../hooks';
import { WebSocketContext } from '@src/context/ws-context';
import { useSendCameraPitchMutation } from '@src/features/orders/redux/api';
import { useSelector } from 'react-redux';
import debounce from 'lodash.debounce';
import { selectorSelectedGroupOrder } from '@src/features/orders/redux/extra-store/selectors';

const DroneCamera: React.FC<DroneCameraProps> = (props) => {
  const { droneSerialNumber } = props;

  const [sendPitch, { isLoading: isPitchLoading }] = useSendCameraPitchMutation();
  const selected = useSelector(selectorSelectedGroupOrder);

  const [cameraEuler, setCameraEuler] = React.useState<any>({});
  const { subscribe, unsubscribe } = React.useContext(WebSocketContext);
  const { isOpen, isLoading, onStartVideo, onStopVideo, videoRef } = useDroneCamera(droneSerialNumber || '');

  const debouncedSendPitch = React.useMemo(
    () => debounce((pitch: number) => {
      if (!selected?.missionId) return;

      sendPitch({
        pitch: pitch,
        command: 'GIMBAL_CONTROL',
        missionId: selected.missionId,
      });
    }, 300),
    [sendPitch, selected]
  );

  const onSliderControl = (pitch: number | number[]) => {
    debouncedSendPitch(pitch as number);
  };

  React.useEffect(() => {
    subscribe('camera_attitude_euler', (event: { value: {} }) => {
      setCameraEuler(event.value);
    });
    return () => unsubscribe('camera_attitude_euler');
  }, [subscribe, unsubscribe]);

  React.useEffect(() => {
    return () => onStopVideo();
  }, []);


  const isDisabledSlider = Object.keys(cameraEuler).length < 1 || isPitchLoading;


  if (isOpen) {
    return (
      <Flex 
        p='12px'
        w='100%' 
        direction='column'
        position='relative'>

        <Flex >
          {/* {isLoading &&
            <Skeleton 
              position='absolute'
              top={0}
              left={0}
              right={0}
              width='100%' 
              height='190px'
              startColor='blackAlpha.400'
              endColor='blackAlpha.700' /> 
          } */}
          <video
            autoPlay 
            playsInline
            width='100%' 
            height='190px'
            ref={videoRef}
          />

          <Slider 
            min={0}
            max={100}
            vertical
            defaultValue={cameraEuler.pitch_deg ?? 100}
            disabled={isDisabledSlider}
            onChange={onSliderControl}
            style={{ height: 190, width: 15 }}
            styles={{ 
              rail: { width: 10, borderRadius: 2  }, 
              track: { width: 10, borderRadius: 2  },  
              tracks: { borderRadius: 2 },
              handle: { width: 15, height: 10, padding: 0, borderRadius: 2, marginLeft: -2.5 } }} 
          />
        </Flex>
        
        <IconButton 
          mt='12px'
          mx='auto'
          width='50px'
          height='50px' 
          colorScheme='red'
          aria-label='Stop Video'
          isLoading={isLoading}
          onClick={onStopVideo} 
          icon={<NotAllowedIcon w='24px' height='24px' />}
        />
      </Flex>
    );
  }
  return (
    <IconButton
      mx='auto'
      width='50px'
      height='50px'
      variant='brand'
      aria-label='Start Video'
      isLoading={isLoading}
      onClick={onStartVideo}
      icon={<VideoIcon />}
    />
  );
};

export default DroneCamera;
