import React from 'react';
import { Flex, Skeleton, useToast } from '@chakra-ui/react';
import { ParameterItem, WeatherSystemSwitch } from './components';
import { useGetSettingsQuery, useSetSettingsMutation } from '../../redux/api';
import { SettingModel } from '../../model';

const ParameterPanel: React.FC = () => {

  const toast = useToast();
  const { data: parameters, isLoading } = useGetSettingsQuery({});
  const [ setParameter, { isLoading: isSetParameterLoading } ] = useSetSettingsMutation();

  const onChangeParameter = React.useCallback(async (parameter: SettingModel[]): Promise<void> => {
    setParameter(parameter).unwrap()
      .then(() => {
        toast({ 
          status: 'success', 
          isClosable: true ,
          title: 'Your request was submitted'
        });
      });
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Flex gap='12px' direction='column'> 
      <WeatherSystemSwitch />
      <Flex 
        gap='12px'
        p='12px 18px'
        direction='column'>
        {parameters?.map((p) => (
          <ParameterItem
            key={p.key} 
            parameter={p}
            isLoading={isSetParameterLoading}
            onChangeParameter={onChangeParameter} />
        ))}
      </Flex>
    </Flex>
  );
};

const Loading: React.FC = () => {
  return (
    <Flex gap='8px' direction='column'>
      <Skeleton w='100%' h='40px' opacity='0.3' />
      <Skeleton w='100%' h='40px' opacity='0.3' />
      <Skeleton w='100%' h='40px' opacity='0.3' />
      <Skeleton w='100%' h='40px' opacity='0.3' />
      <Skeleton w='100%' h='40px' opacity='0.3' />
      <Skeleton w='100%' h='40px' opacity='0.3' />
    </Flex>
  );
};

export default ParameterPanel;