import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { axiosBaseQuery } from "@src/service";
import { IGetGroupOrderResponse, IGetRequestAuthResponse, IOrders, IPostCreateOrderRequest, IPutAssignDroneResponse, IPutModifyRouteRequest, IPutUpdateOrderRequest, IPutUpdateOrderResponse } from "./types";
import { EmergencyStatusEnum } from "../models/EmergencyModel";

export const orderApi = createApi({
  baseQuery: axiosBaseQuery({ baseUrl: '' }),
  reducerPath: 'orderApi',
  tagTypes: ['Orders'],
  endpoints: (builder) => ({

    createOrder: builder.mutation<{}, IPostCreateOrderRequest>({
      query: (data) => ({
        url: 'orders',
        method: 'POST',
        data,
      }),
      invalidatesTags: ['Orders'],
    }),

    updateOrder: builder.mutation<{}, IPutUpdateOrderRequest>({
      query: (data) => ({
        url: 'orders',
        method: 'PUT',
        data,
      }),
      invalidatesTags: ['Orders'],
    }),

    getOrder: builder.query<IPutUpdateOrderResponse, { id: number | string }>({
      query: (data) => ({
        url: `orders/${data.id}`,
        method: 'GET',
      })
    }),

    getGroupOrder: builder.query<IGetGroupOrderResponse, { missionId?: string }>({
      query: (data) => ({
        url: `orders/mission/${data.missionId}`,
        method: 'GET',
      }),
      transformResponse: (response: IGetGroupOrderResponse) => {
        const wps = (response.waypoints || []);

        return {
          ...response,
          waypoints: wps.map((wp, idx) => {
            if (idx === 0 || idx === wps.length - 1) {
              return {
                ...wp,
                type: 'drone',
              };
            }
            if (wp.orderId) {
              return {
                ...wp,
                type: 'order'
              };
            }
            return {
              ...wp,
              type: 'wayPoint'
            };
          })
        };
      }
    }),

    getOrders: builder.query({
      query: () => ({
        url: '/orders?page=1&size=100',
        method: 'GET',
      }),
      providesTags: ['Orders'],
      transformResponse(baseQueryReturnValue: IOrders) {
        const { new: newOrders, ...groupOrders } = baseQueryReturnValue;
        return {
          orders: baseQueryReturnValue,
          newOrders: newOrders || [],
          groupOrders: Object.entries({...groupOrders})
        };
      },
    }),
    cancelOrder: builder.mutation<{}, { id: string | number }>({
      query: (data) => ({
        url: `/orders/${data.id}/cancel`,
        method: 'PUT',
      })
    }),

    cancelMissionOrder: builder.mutation<void, string>({
      query: (id) => ({
        url: `/orders/mission/${id}/cancel`,
        method: 'PUT',
      })
    }),
    requestAuth: builder.mutation<IGetRequestAuthResponse, { missionId: string }>({
      query: (data) => ({
        url: `/orders/${data.missionId}/authorization`,
        method: 'GET',
      }),
      invalidatesTags: ['Orders'],
    }),
    takeOff: builder.mutation<{}, { missionId: string }>({
      query: (data) => ({
        url: `orders/${data.missionId}/TAKE_OFF`,
        method: 'POST',
      })
    }),
    sendCommand: builder.mutation<{}, { missionId: string, command: EmergencyStatusEnum }>({
      query: (data) => ({
        url: `orders/${data.missionId}/${data.command}`,
        method: 'POST',
      })
    }),
    assignDrone: builder.mutation<IPutAssignDroneResponse, {}>({
      query: (data) => ({
        url: 'orders/assign-drone',
        method: 'PUT',
        data,
      })
    }),
    modifyRoute: builder.mutation<void, IPutModifyRouteRequest>({
      query: (data) => ({
        url: 'orders/modify-routes',
        method: 'PUT',
        data,
      }),
    }),
    autoAssignDrone: builder.mutation<{ missionId: string }, number>({
      query: (orderId) => ({
        url: `/orders/${orderId}/auto-assign-drone`,
        method: 'PUT',
      }),
      invalidatesTags: ['Orders'],
    }),
    sendCameraPitch: builder.mutation({
      query: ({ missionId, command, pitch }) => ({
        url: `orders/${missionId}/${command}/${pitch}`,
        method: 'POST',
      })
    })
  })
});

export const { 
  useLazyGetGroupOrderQuery,
  useLazyGetOrdersQuery,
  useGetOrdersQuery,
  useTakeOffMutation,
  useLazyGetOrderQuery,
  useSendCommandMutation,
  useRequestAuthMutation,
  useCreateOrderMutation,
  useCancelOrderMutation,
  useUpdateOrderMutation,
  useAssignDroneMutation,
  useModifyRouteMutation,
  useCancelMissionOrderMutation,
  useAutoAssignDroneMutation,
  useSendCameraPitchMutation,
} = orderApi;