import React from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { AssignSidebarProps } from './types';
import { SectionWrapper } from '../../components';
import { DronesSection, OrdersSection } from './sections';

const Sidebar: React.FC<AssignSidebarProps> = (props) => {
  const { 
    drones, isDronesLoading,
    newOrders, isNewOrdersLoading,
    missionOrders, isMissionOrdersLoading,
    onToggleOrder, onDragDropMissionOrder,
  } = props;


  return (
    <Flex 
      p='12px'
      pb='100px'
      gap='18px'
      width='100%' 
      maxWidth='400px' 
      direction='column' 
      bgColor='block.cloud'
      overflowY='auto'>
    
      <Text color='white' fontSize='22px' fontWeight={700}> Plan route </Text>
      
      <SectionWrapper title='New Orders'>
        <OrdersSection 
          type='ADD' 
          orders={newOrders} 
          isLoading={isNewOrdersLoading} 
          onToggleOrder={onToggleOrder} />
      </SectionWrapper>

      <SectionWrapper title='Added to mission (6 max)'>
        <OrdersSection 
          type='REMOVE' 
          orders={missionOrders} 
          isLoading={isMissionOrdersLoading}
          onToggleOrder={onToggleOrder}
          onDragDropOrder={onDragDropMissionOrder} />
      </SectionWrapper>

      <SectionWrapper title='Available drones'>
        <DronesSection 
          drones={drones} 
          isLoading={isDronesLoading} />
      </SectionWrapper>
    
    </Flex>
  );
};

export default Sidebar;