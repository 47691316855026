import React from 'react';
import { useSelector } from 'react-redux';
import { Flex, IconButton, Text, useDisclosure } from '@chakra-ui/react';
import { selectSelectedDrone } from '@src/features/drones/redux/extra-store/selectors';

import { DeleteIcon, EditIcon, RepeatIcon } from '@chakra-ui/icons';
import DeleteDroneDialog from '../delete-drone-dialog';
import { RegisterDrone } from '@src/features/drones/components';

const ContentHeader: React.FC = () => {

  const selectedDrone = useSelector(selectSelectedDrone);

  const { isOpen: isUpdate, onClose: onCloseUpdate, onOpen: onOpenUpdate } = useDisclosure();
  const { isOpen: isDelete, onClose: onCloseDelete, onOpen: onOpenDelete } = useDisclosure();

  if (!selectedDrone) return null;
    
  return (
    <React.Fragment>
      <Flex 
        w='100%'
        h='50px'
        align='center' 
        justify='space-between'>

        <Flex gap='12px' align='center' fontSize='22px' fontWeight={700}>
          <Text color='white'> {selectedDrone.name} </Text>
          <Text color='txt.secondary'> {selectedDrone.serialNumber} (Drone version) </Text>
        </Flex>

        <Flex gap='12px' align='center'>
          <IconButton 
            w='80px' 
            aria-label='refreshmicon' 
            icon={<RepeatIcon />} />

          <IconButton 
            w='80px'
            colorScheme='red'
            aria-label='delete-icon' 
            onClick={onOpenDelete}
            icon={<DeleteIcon />} />
          <IconButton 
            w='80px'
            colorScheme='primaryBrand'
            aria-label='update-icon' 
            onClick={onOpenUpdate}
            icon={<EditIcon />} />
        </Flex>

      </Flex>

      <DeleteDroneDialog key={`delete-${selectedDrone.id}`} isOpen={isDelete} onClose={onCloseDelete} drone={selectedDrone} />
      <RegisterDrone key={`update-${selectedDrone.id}`} title='Edit Drone' isOpen={isUpdate} onClose={onCloseUpdate} drone={selectedDrone} />

    </React.Fragment>
  );
};

export default ContentHeader;