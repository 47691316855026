import React from 'react';
import { Flex, Switch, Text, useToast } from '@chakra-ui/react';
import { useGetSystemSwitchQuery, useSetSystemSwitchMutation } from '@src/features/settings/redux/api';

const WeatherSystemSwitch: React.FC = () => {

  const toast = useToast();
  const { data, isLoading: isGetSwitchLoading } = useGetSystemSwitchQuery({ });
  const [ setSystemSwitch, { isLoading: isSetSwitchLoading } ] = useSetSystemSwitchMutation();

  const onChangeSystemSwitch = () => {
    setSystemSwitch(data?.status === 'on' ? 'off' : 'on').unwrap()
      .then(() => {
        toast({ 
          status: 'success', 
          isClosable: true, 
          title: 'Your request was submitted', 
        });
      });
  };

  return (
    <Flex w='100%' align='center' justify='space-between'>
      <Text 
        fontSize='16px'
        fontWeight={600}
        color='txt.primary'> 
        WEATHER SYSTEM
      </Text>

      <Switch 
        size='lg' 
        colorScheme='primaryBrand'
        disabled={isGetSwitchLoading || isSetSwitchLoading}
        onChange={onChangeSystemSwitch}
        isChecked={data?.status === 'on'} />
        
    </Flex>
  );
};

export default WeatherSystemSwitch;