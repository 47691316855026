import React from 'react';
import { Flex, Select, Skeleton, Table, TableContainer, Tbody, Td, Th, Thead, Tr, useToast } from '@chakra-ui/react';
import { useGetUserRolesQuery, useGetUsersQuery, useSetUserRoleMutation } from '@src/features/settings/redux/api';

const UserPanel: React.FC = () => {

  const toast = useToast();
  const { data: users, isLoading: isUsersLoading } = useGetUsersQuery({});
  const { data: roles, isLoading: isRolesLoading } = useGetUserRolesQuery({});
  const [setUserRole, { isLoading: isSetRoleLoading }] = useSetUserRoleMutation();

  const onChangeUserRole = async (id: number, role: string): Promise<void> => {

    setUserRole({ id, role }).unwrap()
      .then(() => {
        toast({ 
          status: 'success', 
          isClosable: true ,
          title: 'Your request was submitted'
        });
      });
  };


  if (isUsersLoading || isRolesLoading) {
    return <Loading />;
  }
    
  return (
    <TableContainer w='100%'>
      <Table size='sm' variant='unstyled'>
        <Thead>
          <Tr>
            <Th color='txt.primary'> Full Name </Th>
            <Th color='txt.primary'> Email </Th>
            <Th color='txt.primary'> Role </Th>
          </Tr>
        </Thead>
        <Tbody>
          {users?.data?.map((u) => (
            <Tr key={u.id}>
              <Td 
                color='txt.primary' 
                fontSize='14px'> 
                {`${u.firstname} ${u.lastname}`} 
              </Td>

              <Td 
                color='txt.primary'
                fontSize='14px'> 
                {u.email} 
              </Td>

              <Td 
                color='txt.primary' 
                fontSize='14px'> 
                <Select 
                  value={u.role} 
                  disabled={isSetRoleLoading}
                  onChange={(e): Promise<void> => onChangeUserRole(u.id, e.target.value)}>

                  {roles?.map((role) => (
                    <option key={role} value={role}>
                      {role}
                    </option>
                  ))}

                </Select>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

const Loading: React.FC = () => {
  return (
    <Flex gap='8px' direction='column'>
      <Skeleton w='100%' h='40px' opacity='0.3' />
      <Skeleton w='100%' h='40px' opacity='0.3' />
      <Skeleton w='100%' h='40px' opacity='0.3' />
      <Skeleton w='100%' h='40px' opacity='0.3' />
      <Skeleton w='100%' h='40px' opacity='0.3' />
      <Skeleton w='100%' h='40px' opacity='0.3' />
    </Flex>
  );
};


export default UserPanel;