import React from 'react';
import { FormControl } from '@src/components';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useRegisterDroneMutation } from '../../redux/api';
import { Button, Flex, Input, Modal, ModalContent, ModalOverlay, Text, useToast } from '@chakra-ui/react';

import { IRegisterForm, RegisterDroneProps } from './types';


const RegisterDrone: React.FC<RegisterDroneProps> = (props) => {
  const { title, isOpen, onClose, drone } = props;

  const toast = useToast();
  const [registerDrone, { isLoading }] = useRegisterDroneMutation();

  const { register, handleSubmit, formState: { errors } } = useForm<IRegisterForm>({
    defaultValues: {
      name: drone?.name
    }
  });

  const onSubmit: SubmitHandler<IRegisterForm> = async (form) => {
    if (drone) {
      registerDrone({ id: drone.id, name: form.name }).unwrap()
        .then(() => {
          toast({ 
            status: 'success', 
            isClosable: true, 
            title: 'Your request was submitted', 
          });
        })
        .finally(() => onClose());
    }
  };


  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent 
        bg='linear-gradient(0deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05)), #121212'>
        <Flex p='24px' direction='column'>
          <Text color='white' fontWeight={700}> {title} </Text>
          <Text  mt='12px' mb='8px' color='white' fontSize='12px'> Drone ID - {drone?.id} </Text>
          <form onSubmit={handleSubmit(onSubmit)}>

            <FormControl
              isInvalid={Boolean(errors?.name)}
              helperText={errors?.name?.message}>
              <Input
                autoComplete='off'
                placeholder='Drone name' 
                focusBorderColor='border.primary'
                {...register('name', { required: 'This field is required' })} />
            </FormControl>

            <Flex gap='12px' mt='12px'>
              <Button type='submit' colorScheme='primaryBrand' isLoading={isLoading}> Save </Button>
              <Button onClick={onClose} isLoading={isLoading}> Cancel </Button>
            </Flex>

          </form>
        </Flex>
      </ModalContent>
    </Modal>
  );
};

export default RegisterDrone;