import React from 'react';


import { routes } from '@src/shared/routes';
import { Navigate } from 'react-router-dom';
import { useSignInMutation } from '../../redux/api';
import { useDispatch, useSelector } from 'react-redux';
import { SubmitHandler, useForm } from 'react-hook-form';
import { setAuthStatus } from '../../redux/extra-store/actions';
import { selectAuthStatus } from '../../redux/extra-store/selectors';
import { useToast, Flex, Input } from '@chakra-ui/react';
import { AuthWrapper, AuthSubmit } from '../../components';
import { ISignInForm } from './types';
import { FormControl } from '@src/components';
import { ReactComponent as ArcLogo } from 'src/assets/arc_primary_logo.svg';


const SignInPage: React.FC = () => {
    
  const toast = useToast();
  const dispatch = useDispatch();
  const authStatus = useSelector(selectAuthStatus);
  const [signIn, { isLoading }] = useSignInMutation();
  const { register, handleSubmit, formState: { errors } } = useForm<ISignInForm>();

  const onSubmit: SubmitHandler<ISignInForm> = async (form) => {
    signIn(form).unwrap()
      .then((data) => { 
        dispatch(setAuthStatus(true));
        localStorage.setItem('token', data.id_token);
      });
  };
    

  if (authStatus) return <Navigate to={routes.order} />;


  return (
    <AuthWrapper> 
      <form onSubmit={handleSubmit(onSubmit)}>

        <Flex mt='-24px' gap='12px' flexDirection='column'>

          <Flex width='100%' height='100px' justifyContent='center'>
            <ArcLogo />
          </Flex>

          <FormControl 
            isInvalid={Boolean(errors?.email)}
            helperText={errors?.email?.message}>
            <Input 
              autoComplete='off'
              placeholder='Email' 
              focusBorderColor='border.primary'
              {...register('email', { required: 'This field is required' })} />
          </FormControl>

          <FormControl
            isInvalid={Boolean(errors?.password)}
            helperText={errors?.password?.message}>
            <Input 
              type='password'
              autoComplete='off'
              placeholder='Password'
              focusBorderColor='border.primary'
              {...register('password', { required: 'This field is required' })} />
          </FormControl>
                        
        </Flex>

        <AuthSubmit 
          toText='SIGN UP'
          to='/auth/signUp'
          buttonText='SIGN IN'
          isLoading={isLoading} />
      </form>
    </AuthWrapper>
  );
};

export default SignInPage;