/* eslint-disable @typescript-eslint/no-explicit-any */
import { createStandaloneToast } from '@chakra-ui/react';
import { isRejectedWithValue, Middleware } from '@reduxjs/toolkit';

const { toast } = createStandaloneToast();

export const rtkErrorLogger: Middleware = () => (next) => (action: any) => {
  if (isRejectedWithValue(action)) {
    toast({
      status: 'error',
      isClosable: true,
      title: action?.payload?.response?.data?.message || action?.payload?.message  || 'Something went wrong'
    });
  }
  return next(action);
};
